import style from './index.module.scss';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { IntrinsicProps, createComponent, IconFA } from 'react-commons';

interface RatingsBadgeProps extends IntrinsicProps {
  rating: string
}

export default createComponent<RatingsBadgeProps>('RatingsBadge', { style }, function RatingsBadge ({}, props) {
  return (
    <span className='RatingsBadge Button Button--secondary Button--small'>
      <IconFA icon={faStar} /> <span>{props.rating}</span>
    </span>
  );
});
