import { Button, ButtonLink, Buttons, createComponent, Flex, IconFA, If, IntrinsicProps } from 'react-commons';

import style from './index.module.scss';
import Link from 'next/link';
import { format } from 'date-fns';
import GameTileImg from '../GameTileImg';
import RatingsBadge from '../RatingsBadge';
import StatsLabel from '../StatsLabel';
import { MakeOptional } from '@/types';
import { ExtendedGameData } from '@/lib/drupal/models/Games';
import GamepadIcon from '@/components/GamepadIcon';
import { useState, useEffect } from 'react';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { useIsPremiumUser } from '@/lib/hooks/useIsPremiumUser';
import { useDetectOperatingSystem } from '@/lib/hooks/useDetectOs';

interface SwGameRowProps extends IntrinsicProps, MakeOptional<ExtendedGameData, 'uid' | 'keyword' | 'paidgameBuyUrl' | 'paidgamePrice' | 'mobileIcon' | 'mobileThumbnail' | 'thumbnailImg' | 'thumbnailImgTall' | 'thumbnailImgSmall'> {
  isDownloadGame?: boolean
  useSmallImg?: boolean
}

export default createComponent<SwGameRowProps>('SwGameRow', { style }, function SwGameRow ({ className }, props) {
  const [ time, setTime ] = useState('');
  useEffect(() => {
    if (!props.publishedAt && !props.lastUpdated) return;
    setTime(format(props.publishedAt || props.lastUpdated, 'dd MMM yyyy'));
  }, [ props.publishedAt, props.lastUpdated ]);

  const [ isPremiumUser, isLoading ] = useIsPremiumUser();
  const os = useDetectOperatingSystem();

  const rating = props.rating === '0.0'
    ? '—'
    : Number(props.rating).toFixed(1);

  return (
    <Flex className={className}>
      <div>
        <Link href={props.href}>
          <a>
            <GameTileImg 
              src={props.useSmallImg 
                ? props.thumbnailImgSmall
                : props.thumbnailImg
              } 
              title={props.title}
              small={props.useSmallImg}
            />
          </a>
        </Link>
      </div>
      <Flex directionColumn alignStart>
        <Link href={props.href}>
          <a>
            <p><strong dangerouslySetInnerHTML={{ __html: props.title }} /></p>
            <p><small dangerouslySetInnerHTML={{ __html: props.description }} /></p>
          </a>
        </Link>
        <Flex pullBottom>
          {
            If(props.isDownloadGame && os !== 'Windows', () => (
              <Button disabled secondary small>
                Play Now (Windows Only)
              </Button>
            )).ElseIf(props.isDownloadGame, () => (
              <Buttons>
                {
                  If(!isLoading && isPremiumUser, () => (
                    <ButtonLink href={props.downloadUrl} secondary small>
                      Play Now <GamepadIcon />
                    </ButtonLink>
                  )).Else(() => (
                    <ButtonLink href={props.href} secondary small>
                      Try Free <GamepadIcon />
                    </ButtonLink>
                  )).EndIf()
                }
                <ButtonLink href={props.paidgameBuyUrl} primary small>
                  Buy Now&nbsp;&nbsp;&nbsp;&nbsp; <IconFA icon={faCartShopping} />
                </ButtonLink>
              </Buttons>
            )).Else(() => (
              <ButtonLink href={props.href} secondary small>
                Play Now <GamepadIcon />
              </ButtonLink>
            )).EndIf()
          }
        </Flex>
      </Flex>
      <Flex directionColumn justifyCenter pullRight>
        <p>
          <RatingsBadge rating={rating} />
        </p>
        <p><StatsLabel value={props.numRatings}>ratings</StatsLabel></p>
        <p><StatsLabel value={props.numPlays}>plays today</StatsLabel></p>
        <p><small>{time}</small></p>
      </Flex>
    </Flex>
  );
});

export const SwGameRowSkeleton = createComponent('SwGameRow', { style }, function SwGameRowSkeleton ({ className }, props) {
  return (
    <Flex className={className + ' SwGameRow--skeleton'}>
      <div>
        <span>
          <GameTileImg 
            small
            src='/images/gameTilePlaceholderSmall.png' 
            title='Game tile placeholder'
          />
        </span>
      </div>
      <Flex directionColumn alignStart>
        <span>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </span>
        <Flex pullBottom>
          <Button secondary small>Play Now</Button>
        </Flex>
      </Flex>
      <Flex directionColumn justifyCenter pullRight>
        <p>
          <RatingsBadge rating=' — ' />
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </Flex>
    </Flex>
  );
});
